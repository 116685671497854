var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{ref:"modal_create_entry",attrs:{"modal-class":"slide-up","size":"lg","on-hide":_vm.clearEntryToSave,"modal-header-title":_vm.$t('taxi.create taxi type')},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('ValidationObserver',{ref:"form_create_entry",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
var validated = ref.validated;
return [_c('form',{attrs:{"novalidate":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveEntry)}}},[_c('div',{staticClass:"modal-body"},[_c('AppInput',{attrs:{"name":"name","label":_vm.$t('taxi.taxi type'),"type":"string","rules":"required"},model:{value:(_vm.entryToSave.name),callback:function ($$v) {_vm.$set(_vm.entryToSave, "name", $$v)},expression:"entryToSave.name"}}),_c('AppInput',{attrs:{"name":"cars_num","label":_vm.$t('taxi.taxi cars num'),"type":"int","rules":"required"},model:{value:(_vm.entryToSave.cars_num),callback:function ($$v) {_vm.$set(_vm.entryToSave, "cars_num", $$v)},expression:"entryToSave.cars_num"}})],1),_c('div',{staticClass:"modal-footer"},[_c('button',{class:['btn btn-default btn-cons no-margin inline btn-modal-cancel'],attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('button',{class:['btn btn-cons inline btn-modal-submit', 'btn-complete'],attrs:{"disabled":!valid && validated,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])])])]}}])})]},proxy:true}])}),_c('Modal',{ref:"modal_edit_entry",attrs:{"modal-class":"slide-up","size":"lg","on-hide":_vm.clearEntryToEdit,"modal-header-title":_vm.$t('taxi.update taxi type')},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('ValidationObserver',{ref:"form_update_entry",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
var validated = ref.validated;
return [_c('form',{attrs:{"novalidate":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.updateEntry)}}},[_c('div',{staticClass:"modal-body"},[_c('AppInput',{attrs:{"name":"name","label":_vm.$t('taxi.taxi type'),"type":"string","rules":"required"},model:{value:(_vm.entryToEdit.name),callback:function ($$v) {_vm.$set(_vm.entryToEdit, "name", $$v)},expression:"entryToEdit.name"}}),_c('AppInput',{attrs:{"name":"cars_num","label":_vm.$t('taxi.taxi cars num'),"type":"int","rules":"required"},model:{value:(_vm.entryToEdit.cars_num),callback:function ($$v) {_vm.$set(_vm.entryToEdit, "cars_num", $$v)},expression:"entryToEdit.cars_num"}})],1),_c('div',{staticClass:"modal-footer"},[_c('button',{class:['btn btn-default btn-cons no-margin inline btn-modal-cancel'],attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('button',{class:['btn btn-cons inline btn-modal-submit', 'btn-complete'],attrs:{"disabled":!valid && validated,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])])])]}}])})]},proxy:true}])}),_c('BasicTable',{ref:"table",attrs:{"table-name":_vm.tableName,"settingColumns":_vm.columns,"actions":_vm.actions,"settingTools":_vm.tools,"setting-apis":_vm.apis}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }